@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebar-transition {
  transition: transform 0.3s ease-in-out;
}
/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* For horizontal scrollbar */
::-webkit-scrollbar-horizontal {
  height: 8px;
}

/* Assuming you have a CSS file or a CSS-in-JS solution */
/* Add this to your CSS file or a CSS-in-JS solution */
@media (max-width: 640px) {
  .sliding-btn-tab-container {
    padding: 0.5rem; /* Reduce padding */
    max-width: 90%; /* Ensure it fits within the screen */
  }

  .sliding-btn-tab {
    font-size: 0.75rem; /* Reduce font size */
    padding: 0.25rem 0.5rem; /* Adjust button padding */
    margin: 0 0.1rem; /* Adjust button margin */
  }
}

/* Add smooth height transitions for accordion content */
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordion-content.expanded {
  max-height: 500px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.accordion-content.expanded {
  max-height: 500px; /* Adjust this value as needed */
  opacity: 1;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}


@layer components {
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
  .inbox-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
}


.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link.active {
  color : #fea116;
}

.navbar-dark .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #FFFFFF;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.carousel-caption {
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
.bg-image {
  background-image: url('../../public/assets/img/carousel-1.jpg');
}


/* Hide scrollbar for Chrome, Safari and Edge */
.sidebar-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.sidebar-scrollbar {
  scrollbar-width: none;
}

/* Ensure smooth scrolling for swiping */
.sidebar-scrollbar {
  -webkit-overflow-scrolling: touch; /* iOS support */
  scroll-behavior: smooth;
}


.calculator-transition {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.calculator-visible {
  opacity: 1;
}




.card {
  @apply bg-white rounded-xl shadow-sm border border-mercury/50 transition-all duration-200 hover:shadow-md;
}

.card-header {
  @apply flex items-center gap-2 text-[#2d3436] font-medium;
}

.help-icon {
  @apply w-4 h-4 text-bluish-grey/70 hover:text-bluish-grey transition-colors cursor-help;
}

.stat-value {
  @apply text-2xl font-semibold tracking-tight;
}

.dropdown-button {
  @apply flex items-center gap-1.5 px-3 py-1.5 text-sm text-bluish-grey hover:text-hazel-green rounded-md hover:bg-seashell transition-colors;
}


.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: theme('colors.bluish-grey') theme('colors.grey-goose');
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: theme('colors.grey-goose');
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: theme('colors.bluish-grey');
  border-radius: 3px;
  transition: background-color 0.2s;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: theme('colors.hazel-green');
}

/* Hide scrollbar for Chrome, Safari and Opera */
.custom-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

/* Smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}









/* Custom Scrollbar */
.custom-scrollbar2::-webkit-scrollbar {
  height: 8px; /* Height for horizontal scrollbar */
}

.custom-scrollbar2::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Light background track color */
}

.custom-scrollbar2::-webkit-scrollbar-thumb {
  background-color: #61805b; /* Hazel green color */
  border-radius: 4px; /* Rounded scrollbar */
}

.custom-scrollbar2::-webkit-scrollbar-thumb:hover {
  background-color: #61805b; /* Darker hazel green on hover */
}


/* styles.css or your relevant CSS file */
.bg-checkin-color {
  background-color: #ffeb3b; /* Example color for check-in */
}

.bg-reserved-color {
  background-color: #f44336; /* Example color for reserved */
}
