New Styles for Merged Tables
#table-wrapper.merged-table {
    background-color: #8b5e34; /* Different background color for merged tables */
    border: 2px dashed #000; /* Dashed border for merged tables */
    width: 160px; /* Reduced from 200px */
    height: 160px; /* Reduced from 200px */
    transition: all 0.3s ease; /* Smooth transition when merged */
}

#table-wrapper.merged-table .the-table {
    background-color: #7a5633; /* Darker color to indicate merging */
    width: 120px; /* Reduced from 150px */
    height: 120px; /* Reduced from 150px */
}

#table-wrapper.merged-table .chair {
    width: 28px; /* Adjusted chair size for merged tables */
    height: 35px; /* Adjusted chair height for merged tables */
}

/* Adding styles to make the connection more seamless */
#table-wrapper.merged-table .chair:before {
    width: 28px; /* Adjusted size for merged tables */
    height: 10px; /* Adjusted size for merged tables */
}

#table-wrapper.dragging {
    animation: scaleAnimation 0.6s infinite alternate;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}



/* table.css */
.table-container {
    position: absolute;
    user-select: none;
    touch-action: none;
    transition: transform 0.1s ease-out;
    will-change: transform;
    cursor: move;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.table-container * {
    user-select: none;
}

.table-container::before {
    content: '';
    position: absolute;
    inset: -4px;
    border-radius: inherit;
    transition: outline-color 0.2s ease;
    pointer-events: none;
}

.table-container.dragging {
    z-index: 1000;
    opacity: 0.8;
    cursor: grabbing;
}

.merge-highlight {
    outline: 2px dashed #4299e1;
    outline-offset: 4px;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.merged-table {
    outline: 2px solid #4299e1;
    outline-offset: 2px;
}

#table-wrapper {
    width: 80px;  /* Reduced from 100px */
    height: 80px; /* Reduced from 100px */
    position: relative;
    left: 0;
    top: 0;
    transform: rotate(-90deg) scale(0.8); /* Added scale to make it smaller */
}

#table-wrapper .the-table {
    position: absolute;
    left: 12px; /* Adjusted from 15px */
    top: 12px;  /* Adjusted from 15px */
    height: 56px; /* Reduced from 70px */
    width: 56px;  /* Reduced from 70px */
    background-color: #5e4837;
    box-shadow: 0 2px 4pt rgba(0, 0, 0, .23), 0 2px 4pt rgba(0, 0, 0, .16);
}

#table-wrapper .the-table .plate {
    width: 10px;  /* Reduced from 12px */
    height: 10px; /* Reduced from 12px */
    background: white;
    border-radius: 50%;
    position: absolute;
}

#table-wrapper .the-table .plate:before {
    top: 2px; /* Adjusted position */
    left: 2px; /* Adjusted position */
    position: absolute;
    content: " ";
    width: 8px; /* Reduced size */
    height: 8px; /* Reduced size */
    border-radius: 50%;
    box-shadow: 8px 7px 0 0 #eaeaea;
}

#table-wrapper .the-table .plate:after {
    position: absolute;
    content: " ";
    width: 6px; /* Reduced size */
    height: 6px; /* Reduced size */
    border-radius: 50%;
    background-color: #a03434;
    border: 0.5px solid #9a816d;
    left: 4px; /* Adjusted position */
    bottom: -1px; /* Adjusted position */
    box-shadow: inset 0 0 8px #000;
}

#table-wrapper .the-table .plate:nth-child(1) {
    left: 12px;
    top: 2px;
}

#table-wrapper .the-table .plate:nth-child(2) {
    left: 2px;
    top: 12px;
}

#table-wrapper .the-table .plate:nth-child(3) {
    left: 2px;
    top: 48px;
}

#table-wrapper .the-table .plate:nth-child(4) {
    right: 2px;
    top: 12px;
}

#table-wrapper .the-table .plate:nth-child(5) {
    right: 2px;
    top: 48px;
}

#table-wrapper .the-table .plate:nth-child(6) {
    left: 12px;
    top: 64px;
}

#table-wrapper .the-table .knife,
#table-wrapper .the-table .fork {
    position: absolute;
    width: 2px; /* Increased size */
    height: 12px; /* Increased size */
    background-color: #d2d3d5;
    top: 3px; /* Adjusted position */
    box-shadow: 0 3px 9pt rgba(0, 0, 0, .23), 0 3px 9pt rgba(0, 0, 0, .16);
}

#table-wrapper .the-table .knife:before,
#table-wrapper .the-table .fork:before {
    width: 1px; /* Adjusted size */
}

#table-wrapper .the-table .knife {
    border-radius: 0 0 100%/1.67px 0; /* Adjusted border */
    right: -1px; /* Adjusted position */
}

#table-wrapper .the-table .fork {
    border-radius: 0px 0 3px 7px;
    left: -1px; /* Adjusted position */
}

#table-wrapper .the-table .fork:before {
    box-shadow: 0 2.67px 0 0.67px #5e4837;
    border-top-right-radius: 6.67px;
    position: absolute;
    bottom: 0;
    left: 0.67px; /* Adjusted position */
    width: 0.67px; /* Adjusted size */
    height: 3.33px; /* Adjusted size */
    content: ' ';
    background-color: inherit;
}

#table-wrapper .the-table .fork:after {
    box-shadow: 0 2.67px 0 0.67px #5e4837;
    border-top-left-radius: 6.67px;
    position: absolute;
    bottom: 0;
    left: -0.33px; /* Adjusted position */
    width: 0.67px; /* Adjusted size */
    height: 3.33px; /* Adjusted size */
    content: ' ';
    background-color: inherit;
}

#table-wrapper .chair {
    position: absolute;
    width: 16px;  /* Reduced from 20px */
    height: 20px; /* Reduced from 25px */
    background-color: #3e2f24;
    border-radius: 10%/12px 12px 0 0;
    perspective: 40px;
    box-shadow: 0 2px 6pt rgba(0, 0, 0, .23), 0 2px 6pt rgba(0, 0, 0, .16);
}

#table-wrapper .chair:before {
    transform: rotateX(160deg);
    position: absolute;
    width: 20px; /* Reduced size */
    height: 6px; /* Reduced size */
    background-color: #7e614a;
    top: -1px; /* Adjusted position */
    left: -1px; /* Adjusted position */
    content: " ";
    border-radius: 4.33%/16.67px 16.67px 0 0;
}

#table-wrapper .chair.chair-lg {
    width: 18px;  /* Reduced from 22px */
    height: 20px; /* Reduced from 25px */
}

#table-wrapper .chair.chair-lg:before {
    width: 22px; /* Reduced size */
    height: 6px; /* Reduced size */
    left: -1px; /* Adjusted position */
}

#table-wrapper .chair:nth-child(1) {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}

#table-wrapper .chair:nth-child(2) {
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
}

#table-wrapper .chair:nth-child(3) {
    left: 0;
    top: 100%;
    transform: translateY(-100%) rotate(-90deg);
}

#table-wrapper .chair:nth-child(4) {
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
}

#table-wrapper .chair:nth-child(5) {
    right: 0;
    top: 100%;
    transform: translateY(-100%) rotate(90deg);
}

#table-wrapper .chair:nth-child(6) {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) rotate(180deg);
}

/* Table info popup styles */
/* Base table info styles */
.table-info {
  display: none;
}

/* Small screen styles (mobile only) */
@media (max-width: 767px) {
  .info-button {
    display: flex !important;
  }

  .table-info.show {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 90%;
    max-width: 300px;
  }
}

/* Medium and large screen styles */
@media (min-width: 768px) {
  .info-button,
  .table-info,
  .table-info.show {
    display: none !important;
  }
}

.table-management {
    transform: scale(0.9); /* Make the management section slightly smaller */
    margin-top: 0.5rem; /* Reduced from 1rem */
}