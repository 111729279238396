.custom-modal .ant-modal-content {
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.custom-modal .ant-modal-header {
  border-bottom: none;
  padding: 24px 24px 0;
}

.custom-modal .ant-modal-body {
  padding: 0 24px;
}

.custom-modal .ant-modal-footer {
  border-top: 1px solid #c9d3cc; /* grey-goose */
  padding: 16px 24px;
}

.custom-modal .ant-btn-primary {
  height: 38px;
  padding: 0 20px;
  font-weight: 500;
  text-shadow: none;
  box-shadow: none;
  color: white;
}

.custom-modal .ant-btn-default {
  height: 38px;
  padding: 0 20px;
  font-weight: 500;
  border-color: #c9d3cc; /* grey-goose */
  color: #7c8b93; /* bluish-grey */
}

.custom-modal .ant-input {
  border-radius: 6px;
  border-color: #c9d3cc; /* grey-goose */
}

.custom-modal .ant-input:focus {
  border-color: #90a68c; /* sage */
  box-shadow: 0 0 0 2px rgba(144, 166, 140, 0.1); /* sage with opacity */
}

.custom-modal .ant-modal-close {
  top: 18px;
  right: 18px;
}

.custom-modal .ant-modal-close:hover {
  background-color: #F0F3EF; /* seashell */
  border-radius: 50%;
}

.custom-modal .ant-input-data-count {
  color: #7c8b93; /* bluish-grey */
  font-size: 12px;
}
