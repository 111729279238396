@media print {
    @page {
      size: A4;
      margin: 20mm;
    }
  
    body {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  
    .invoice-pdf {
      background: white;
      padding: 30px;
    }
  
    /* Add any specific print styles here */
    .no-print {
      display: none;
    }
  }

.print-container {
  background: white;
  padding: 20px;
  width: 210mm; /* A4 width */
  min-height: 297mm; /* A4 height */
  margin: 0 auto;
}

@media print {
  .print-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15mm;
    box-sizing: border-box;
    page-break-after: always;
  }
}

/* Hide elements that shouldn't appear in PDF */
@media print {
  .no-print {
    display: none !important;
  }
}

.pdf-container {
  width: 100%;
  max-width: 210mm; /* A4 width */
  margin: 0 auto;
  background: white;
  position: relative;
}

/* Responsive adjustments */
@media (min-width: 1024px) { /* lg breakpoint */
  .pdf-container {
    padding: 2rem;
  }
}

@media print {
  .pdf-container {
    padding: 0;
    max-width: none;
  }
  
  button, 
  .no-print {
    display: none !important;
  }
}

/* Ensure all images are loaded with CORS */
.pdf-container img {
  crossorigin: anonymous;
}